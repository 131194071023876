import Aos from 'aos';
import React from 'react';
import Slider from "react-slick";
import styled from 'styled-components';

const data = [
    {
        clientName: 'MICHAEL SCOTT',
        profession: 'Regional Manager, Dunder Mifflin',
        quotes: "My philosophy is basically this. And this is something that I live by. And I always have. And I always will. Don't ever, for any reason, do anything to anyone, for any reason, ever, no matter what. No matter... where. Or who, or who you are with, or, or where you are going, or... or where you've been... ever. For any reason, whatsoever... [In an interview] Sometimes I'll start a sentence and I don't even know where it's going. I just hope I find it along the way. Like an improv conversation. An Improversation."
    },
    {
        clientName: 'MICHAEL SCOTT',
        profession: 'Regional Manager, Dunder Mifflin',
        quotes: "My philosophy is basically this. And this is something that I live by. And I always have. And I always will. Don't ever, for any reason, do anything to anyone, for any reason, ever, no matter what. No matter... where. Or who, or who you are with, or, or where you are going, or... or where you've been... ever. For any reason, whatsoever... [In an interview] Sometimes I'll start a sentence and I don't even know where it's going. I just hope I find it along the way. Like an improv conversation. An Improversation."
    },
];

const Carousel = () => {
    Aos.init();
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div data-aos="fade-left"
            data-aos-offset="200"
            data-aos-duration="500"
            data-aos-easing="ease-in-out">
            <Slider {...settings}>
                {
                    data.map(singleData => {
                        return (
                            <Card>
                                <div>
                                    <h2>{singleData?.clientName}</h2>
                                    <h6>{singleData?.profession}</h6>
                                    <p className='p-2'>{singleData?.quotes}</p>
                                </div>
                            </Card>
                        )
                    })
                }
            </Slider>
        </div>
    );
};

export default Carousel;

const Card = styled.div`
    text-align: center;
    /* border: 1px solid green; */
    background-color:#F7F7F7;
    padding: 103px 7px 103px 7px;

    h2 {
        font-size: 25px;
        font-weight: 800;
        color: #313131;
        line-height: 37px;
        margin: 0;
    }

    h6 {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #B0B0B0;
    }

    p {
        margin-top: 10px;
        font-size: 17px;
        line-height: 25px;
        font-weight: 400;
        text-align: center;
        color: #B0B0B0;
    }

    div {
        max-width: 650px;
        margin: auto;
    }
    
    
`;