import React from 'react';
import styled from 'styled-components';
import Carousel from './Carousel';

const Clientsay = () => {
    return (
        <Wrapper className='overflow-hidden'>
            <div className='clients-wrapper'>
                <h1>WHAT OUR CLIENTS SAY</h1>
                <h1 style={{ color: '#C4C4C4' }}>ABOUT OUR SERVICES</h1>
            </div>
            <Carousel />
        </Wrapper>
    );
};

export default Clientsay;

const Wrapper = styled.div`
    /* margin: 24px 0; */
    .clients-wrapper {
        margin: 90px 0;
    }
    h1 {
        color: #313131;
        font-weight: 800;
        font-size: 50px;
        line-height: 75px;
    }
    .slick-slider {
        position: relative;
    }
    .slick-prev {
        top:380px;
        left: 580px;
        position: absolute;
        z-index: 99;
        @media only screen and (max-width: 1399px) {
            top:380px;
            left: 480px;
         }
         @media only screen and (max-width: 1199px) {
            top:425px;
            left: 220px;
         }
        @media only screen and (max-width: 767px) {
            top:470px;
            left: 140px;
         }
        @media only screen and (max-width: 425px) {
            top: 510px;
            left: 100px;
         }
        @media only screen and (max-width: 375px) {
            top: 580px;
            left: 100px;
         }
         @media only screen and (max-width: 320px) {
            top: 580px;
            left: 40px; 
         }
         
        :before {
            content: url('/icons/arrowL.svg');
            color: #313131;
        }
    }
    .slick-next {
        top:380px;
        right: 580px;
        position: absolute;
        z-index: 99;
        @media only screen and (max-width: 1399px) {
            top:380px;
            right: 520px;
         }
        @media only screen and (max-width: 1199px) {
            top:425px;
            right: 220px;
         }
         @media only screen and (max-width: 767px) {
            top:470px;
            right: 160px;
         }
        @media only screen and (max-width: 425px) {
            top: 510px;
            right: 140px;
         }
        @media only screen and (max-width: 375px) {
            top: 525px;
            right: 140px;
         }
        @media only screen and (max-width: 375px) {
            top: 580px;
            right: 100px;
         }
        :before {
            content:  url('/icons/arrowRight.svg');
            color: #313131;
            /* @media only screen and (max-width: 425px) { 
                left: -130px;
                top: 200px;

            } */
        }
    }
    @media only screen and (max-width: 600px) {
        h1 {
            font-size: 30px;
            line-height: 35px;
        }
        /* margin: 14px 0; */
        .clients-wrapper {
        margin: 35px 0;
    }
    }
`;