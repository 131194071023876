import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Clientsay from '../components/ClientsSay/Clientsay';
import Contact from '../components/Contact/Contact';
import Copyright from '../components/Copyright/Copyright';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import SecondHeader from '../components/SecondHeader/SecondHeader';
import Services from '../components/Services/Services';

const Home = () => {
    return (
        <div>
            <HomeWrapper>
                <Header />
            </HomeWrapper>
            <Container >
                <SecondHeader />
                <Services />
                <div style={{ borderTop: '1px solid #B0B0B0' }}></div>
                <Clientsay />
                <Contact />
                <Footer />
                <Copyright />
            </Container>
        </div>
    );
};

export default Home;

const HomeWrapper = styled.div`

`;