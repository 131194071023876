import React from 'react';
import Recaptcha from 'react-recaptcha';

const ContactRecapcha = () => {
    // console.log(process.env.REACT_APP_CAPTCHA_KEY)
    const verifyCallback = (e) => {
        console.log(e);
    };

    const callback = (e) => {
        console.log('callback', e);
    };

    return (
        <div>
            <Recaptcha
                sitekey='6Lebal4dAAAAALIOwr12PQU4jHZvnJ1fxcn6g3Ik'
                render="explicit"
                verifyCallback={verifyCallback}
                onloadCallback={callback}
            />
        </div>
    );
};

export default ContactRecapcha;