import Aos from 'aos';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const data = [
    {
        title1: 'AT',
        title2: 'PRESENT',
        slug: 'experts',
        description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
        border: true,
        animation: 'fade-up-left'

    },
    {
        title1: 'OUR',
        title2: 'EXPERTS',

        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in science and stuff.",
        animation: 'fade-up-left'

    }
];

const SecondHeader = () => {
    Aos.init();
    return (
        <Wrapper className='mb-5 overflow-hidden' name='at-present'>
            {
                data?.map(singleData => {
                    return (
                        <div name={singleData?.slug} data-aos-duration='1000' data-aos={singleData.animation} className='mt-5 mb-4'>
                            <Row>
                                <Col md={4}>
                                    <h1>{singleData?.title1}</h1>
                                    <h1 style={{ color: '#C4C4C4' }}>{singleData?.title2}</h1>
                                </Col>
                                <Col md={8}>
                                    <p>{singleData?.description}</p>
                                </Col>
                            </Row>
                            <div style={singleData?.border && { borderTop: '1px solid #B0B0B0', marginTop: '50px' }}></div>
                        </div>
                    )
                })
            }
        </Wrapper>
    );
};

export default SecondHeader;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    /* height: 80vh; */
    h1 {
        font-size: 50px;
        font-weight: 800;
        line-height: 75px;
        color: #313131;
    };
    p {
        font-size: 23px;
        font-weight: 400;
        line-height: 37px;
        color: #B0B0B0;
        text-align: justify;
    };
    @media only screen and (max-width: 600px) {
        h1 {
            /* margin-bottom: 13px; */
            font-size: 28px;
            line-height: 35px;
        }
        p {
            margin-top: 13px;
        font-size: 17px;
        font-weight: 400;
        line-height: 23px;
        }
    }
`;