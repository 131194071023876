import Aos from 'aos';
import React, { useState } from 'react';
import { Accordion, Button, Card, Col, Row, useAccordionButton } from 'react-bootstrap';
import styled from 'styled-components';

const data = [
    {
        icon: '/images/forensic.svg',
        title: 'FORENSIC - CRIMINAL',
        animation: 'zoom-in'
    },
    {
        icon: '/images/rataimage.svg',
        title: 'FORENSIC - RTA',
        animation: 'zoom-in'
    },
    {
        icon: '/images/country.svg',
        title: 'COUNTRY - EXPERTS',
        animation: 'zoom-in'
    },
    {
        icon: '/images/physio.svg',
        title: 'REHABILITATION - PHYSIO',
        animation: 'zoom-in'
    },
    {
        icon: '/images/group.svg',
        title: 'REHABILITATION - CBT',
        animation: 'zoom-in'
    },
    {
        icon: '/images/dental.svg',
        title: 'DENTAL - EXPERTS',
        animation: 'zoom-in'
    },
    {
        icon: '/images/human.svg',
        title: 'MENTAL CAPACITY ASSESSMENT',
        animation: 'zoom-in'
    },
    {
        icon: '/images/page.svg',
        title: 'RISK - ASSESSMENT',
        animation: 'zoom-in'
    },
    {
        icon: '/images/eye.svg',
        title: 'EYE EXPERTS',
        animation: 'zoom-in'
    },
    {
        icon: '/images/world.svg',
        title: 'SPEECH AND LANGUAGE',
        animation: 'zoom-in'
    },
    {
        icon: '/images/translation.svg',
        title: 'INTERPRETER AND TRANSLATION',
        animation: 'zoom-in'
    },
    {
        icon: '/images/medical.svg',
        title: 'MEDICAL NEGLIGENCE',
        animation: 'zoom-in'
    },
    {
        icon: '/images/legal.svg',
        title: 'MEDICO - LEGAL',
        animation: 'zoom-in'
    },
    {
        icon: '/images/pagination.svg',
        title: 'PAGINATION',
        animation: 'zoom-in'
    },
    {
        icon: '/images/surveyors.svg',
        title: 'SURVEYORS',
        animation: 'zoom-in'
    },

];

const Services = () => {

    const [expand, setExpand] = useState(false);
    Aos.init();
    // accordion-display-initial accordion-display-expand border
    return (
        <ServicesWrapper className='mb-5 pt-md-5' name='clients'>
            <div className={`${expand && 'accordion-display-expand '} accordion-display-initial`}>
                <Row className='pt-4 align-items-center w-100'>
                    {
                        data.map(singleData => {
                            return (
                                <Col data-aos={singleData?.animation}
                                    data-aos-duration="600"
                                    data-aos-easing="ease-in-out" className=' text-center mb-md-5 mb-4 service-box cursor-pointer' md={4}>
                                    <img className='mx-auto service-images' style={{ width: '120px', height: '100px' }} src={singleData?.icon} alt={singleData?.title} />
                                    <p className='my-5 service-text'>{singleData?.title}</p>
                                </Col>
                            )
                        })
                    }

                </Row>
            </div>
            <div className='text-right d-flex align-items-center justify-content-end'>
                <button onClick={() => setExpand(!expand)} className='d-flex align-items-center justify-content-center'>
                    <img src='/icons/arrowDown.svg' className='mr-2' alt='img' />
                    <span >{expand ? 'COLLAPSE' : 'EXPAND'}</span>
                </button>
            </div>
        </ServicesWrapper>
    );
};

export default Services;

const ServicesWrapper = styled.div`


    button  {
        background: transparent;
        color: gray;
        border: none;
        outline: none;
        padding: 5px 12px;
    }
    .service-text {
        font-size:25px;
        font-weight:700;
         line-height:37px;
         color:#313131;
    }
    .service-images {
        transition: all .5s;
        width: 100px;
        filter: contrast(0%);
    }
    .accordion-display-initial {
        height: auto;
        overflow: hidden;
        transition: max-height .4s ease-in;
        max-height: 585px;
    }
    .accordion-display-expand {
        /* overflow-y: auto; */
        max-height: 4000px;
    }
    @media only screen and (max-width: 600px) {
        .service-images {
        width: 80px;
    }
    .service-text {
        font-size: 20px;
    }
    .accordion-display-initial {
        max-height: 745px;
    }
    .accordion-display-expand {
        /* overflow-y: auto; */
        max-height: 4000px;
    }
    }
    .service-box:hover .service-images {
    filter: contrast(150%);
}
`;