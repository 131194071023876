/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import {
  Link
} from "react-scroll";

const NavigationBar = () => {
  return (
    <Popover style={{ zIndex: 999 }} className="relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="#">
              <span className="sr-only">Workflow</span>
              <img
                style={{ width: '123px', height: '28px' }}
                src="/icons/new-logo.svg"
                alt=""
              />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <div style={{ width: '400px' }} className='d-flex justify-content-between'>
              <h1 className='w-75' style={{ color: '#19335A', fontWeight: 500, fontSize: '20px' }}>Call us: +447934943680</h1>

            </div>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Link style={{ cursor: 'pointer' }} to='contact'>
              <span style={{ textDecoration: 'none', backgroundColor: '#19335A' }} className="whitespace-nowrap text-base font-medium text-white px-4 py-2 hover:text-gray-900 rounded-md">
                Contact
              </span>
            </Link>
            <Link style={{ cursor: 'pointer' }} to='clients'>
              <span
                style={{ border: '1px solid #19335A', color: '#19335A', textDecoration: 'none' }}
                className="ml-3 bg-white whitespace-nowrap inline-flex items-center justify-center px-4 py-2 rounded-md text-base font-medium"
              >
                Clients
              </span>
            </Link>
          </div>

        </div>
        <div className='text-center hidden md:block lg:w-100'>
          <Link to='at-present' href="#" style={{ color: '#19335A', fontWeight: 500, fontSize: '15px' }} className='ms-5'>Who are we?</Link>
          <Link to='experts' style={{ color: '#19335A', fontWeight: 500, fontSize: '15px' }} href="#" className='ms-5' >Join Our Panel</Link>
          <Link style={{ color: '#19335A', fontWeight: 500, fontSize: '15px' }} href="#" className='ms-5' to='contact'>Instructions</Link>
          <Link to='clients' style={{ color: '#19335A', fontWeight: 500, fontSize: '15px' }} href="#" className='ms-5' >Services</Link>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    style={{ width: '123px', height: '28px' }}
                    src="/icons/new-logo.svg"
                    alt=""
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid navs grid-cols-2 gap-y-4 gap-x-8">
                  {/* ok */}
                  <Link to='at-present' style={{ color: '#19335A', fontWeight: 500, fontSize: '15px' }} className='pe-auto'>Who are we?</Link>
                  <Link to='experts' style={{ color: '#19335A', fontWeight: 500, fontSize: '15px' }} className='pe-auto'>Join Our Panel</Link>
                  <Link to='contact' style={{ color: '#19335A', fontWeight: 500, fontSize: '15px' }} >Instructions</Link>
                  <Link to='clients' style={{ color: '#19335A', fontWeight: 500, fontSize: '15px' }} className=''>Services</Link>
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                <Link to='contact'>
                  <span
                    style={{ border: '1px solid #19335A', color: '#19335A', textDecoration: 'none' }}
                    className="me-3 bg-white whitespace-nowrap px-4 py-2 rounded-md text-base font-medium"
                  >
                    Contact
                  </span>
                </Link>
                <Link to='clients'>
                  <span style={{ textDecoration: 'none', backgroundColor: '#19335A' }} className="whitespace-nowrap text-base font-medium text-white px-4 py-2 hover:text-gray-900 rounded-md">
                    Clients
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default NavigationBar;