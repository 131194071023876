import React from "react";
import { useState } from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import NavigationBar from "../NavigationBar/NavigationBar";
import { carouselData } from "./carouselData";
import AOS from 'aos';

const Header = () => {
  const [index, setIndex] = useState(0);
  AOS.init();
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <HeaderWrapper className="overflow-hidden">
      <div className="small-display">
        <NavigationBar />
        <div className="main-content px-5">
          <h1 data-aos="fade-up" data-aos-duration="1000" >EXPERT REPORTING AT A CLICK</h1>
        </div>
      </div>
      <div className="big-display">
        <div className="carousel-box">
          <NavigationBar />
          <div className="header-texts">
            <h1 style={{ fontSize: '45px', lineHeight: '60px', fontWeight: 800, letterSpacing: '4px' }}>
              EXPERT REPORTING AT A  CLICK
            </h1>
          </div>
          {/* <Carousel indicators={false} activeIndex={index} onSelect={handleSelect} className="carousel w-100">
            {
              carouselData?.map(data => {
                return (
                  <Carousel.Item className="carousel-item">
                    <img
                      className="d-block w-100 carousel-image"
                      src='../../images/header.png'
                      alt={data?.title}
                    />
                  </Carousel.Item>
                )
              })
            }
          </Carousel> */}
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div`
    height:100vh;
    z-index:-1;
   
    background-image: url('../../images/header.png');
    background-position:bottom left;
    background-size: cover;
    background-repeat:no-repeat ;
@media only screen and (min-width: 791px) {
  /* background-position:bottom top!important; */
  .small-display{
    display: none;
  }
}
@media only screen and (max-width: 791px) {
  background-position:-250px 0px;
  .small-display{
    display: block;
    z-index:20;
    .main-content{
     margin-top:120px;
    
    h1 {
    font-size:2rem!important;
    color:white;
    text-shadow: 2px 2px 4px #19335A;
  }
}
  }
  
}
@media only screen and (max-width: 791px) {

  .big-display{
    display: none;
  }
}
    .carousel-box {
        position: relative;
        z-index: 999;
        height: 100vh;
    }
    .carousel {
        position: absolute;
        top: 0;
        z-index: -1;
        max-height: 100vh;
    }
    .carousel-image {
        height: 100vh;
    }
    .header-texts {
        color: white;
        max-width: 650px;
        padding: 0 0 0 200px;
        margin-top: 90px;
    }
    @media only screen and (max-width: 1380px) {
      .carousel-image {
          width: auto;
        height: 86vh;
    }
    .carousel-box {
        height: 86vh;
    }
  }
    @media only screen and (max-width: 1024px) {
      .carousel-image {
          width: auto;
        height: 74vh;
    }
    .carousel-box {
        height: 74vh;
    }
    }
    @media only screen and (max-width: 790px) {
      h1 {
              font-size: 2.3rem!important;
              line-height: 49px!important;
              letter-spacing: 2px!important;
            }
      .carousel-image {
          width: auto;
        height: 54vh;
    }
    .carousel-box {
        height: 54vh;
    }
      }
    
     
`;
