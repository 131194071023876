import React from 'react';

const Copyright = () => {
    return (
        <div className='pt-2' style={{borderTop:'1px solid #B0B0B0'}}>
            <p
                className='text-center'
                style={{color:'rgba(75, 75, 75, 0.5)',fontSize:'16px'}}
            >@ Copyright 2020 Starling Exparts. All rights reserved.</p>
        </div>
    );
};

export default Copyright;