import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const GoogleMap = () => {
    const position = [51.5168137, -0.0595791]
    return (
        <>
            <MapContainer center={position} zoom={15} scrollWheelZoom={true} >
                <TileLayer
                    attribution='&copy; starling experts'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                    <Popup>
                        Starling Experts
                    </Popup>
                </Marker>
            </MapContainer>
        </>
    );
};

export default GoogleMap;