import Aos from 'aos';
import React, { useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import ContactRecapcha from './ContactRecapcha';
import emailjs from 'emailjs-com';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import GoogleMap from '../GoogleMap/GoogleMap';
import CircularProgress from '@mui/material/CircularProgress';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Contact = () => {
    const [isSpinnerTrue, setIsSpinnerTrue] = useState(false);
    Aos.init();
    const [userData, setUserData] = useState({
        userName: '',
        email: '',
        phoneNumber: '',
    })

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };



    const form = useRef();
    const handleFormSubmit = (e) => {
        setIsSpinnerTrue(true);
        e.preventDefault();
        // console.log(e);
        // e.preventDefault();
        console.log(form.current);


        emailjs.sendForm('service_dz9q4nx', 'template_r0xd3w5', form.current, 'user_jFyozLCM8J18y21KPtMi3')
            .then((result) => {
                handleClick();
                console.log(result.text);
                setIsSpinnerTrue(false);
            }, (error) => {
                console.log(error.text);
                setIsSpinnerTrue(false);
            });
    };

    const handleInputBlur = (e) => {
        const { name, value } = e.target;
        const newUserData = { ...userData };
        newUserData[name] = value;
        setUserData(newUserData);
    };
    console.log(userData);
    return (
        <ContactWrapper name='contact'>

            <h1 >CONTACT</h1>
            <h1 style={{ color: '#C4C4C4' }}>US</h1>
            <div className='mt-3' style={{ borderTop: '1px solid #B0B0B0' }}></div>
            <div className='mt-5 w-100 d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap overflow-hidden'>
                <div data-aos="fade-right"
                    style={{ width: '600px' }}
                    className='mx-auto'
                    data-aos-duration="800" md={6}>
                    <div className='contact-title'>
                        <h3>LONDON</h3>
                        <h3>129 STAMFORD BRIDGE, 15th FLOOR</h3>
                        <h6 style={{ fontSize: '20px' }}>London, S2E54</h6>
                    </div>
                    <form ref={form} className='mt-4' onSubmit={handleFormSubmit}>
                        <Row className='w-100' >
                            <Col md={10}>
                                <div>
                                    <input name='user_name' placeholder='Your Name' onBlur={handleInputBlur} required />
                                </div>
                                <div>
                                    <input name='user_email' placeholder='Your e-mail address' onBlur={handleInputBlur} required />
                                </div>
                                <div>
                                    <input name='phone_number' type='number' placeholder='Your phone number' onBlur={handleInputBlur} required />
                                </div>
                                <div className='mt-2'>
                                    <textarea name='message' className='p-2' placeholder='Please write your message' style={{ height: '80px', width: '100%' }} required></textarea>
                                </div>
                                <div className='mt-3'>
                                    <ContactRecapcha />
                                </div>

                                <div className='text-center'>
                                    <button style={{ color: 'white', cursor: 'pointer', backgroundColor: 'rgb(25, 51, 90)' }} type='submit' className='w-100 mt-4 px-4 py-2 mx-auto'> {isSpinnerTrue ? <CircularProgress size={24} color="inherit" /> : 'SUBMIT'} </button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
                <div
                    style={{ width: '600px', height: '550px' }}
                    className=' mx-auto'
                >
                    <GoogleMap />
                </div>
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Message sent successfully!
                </Alert>
            </Snackbar>
        </ContactWrapper>
    );
};

export default Contact;

const ContactWrapper = styled.div`
margin: 5rem 0;
    h1 {
        font-size: 50px;
        font-weight: 800;
        line-height: 75px;
        color: #313131;
    }
    textarea {
        border: 1px solid #B0B0B0;
        padding: 9px;
        outline: none;
    }
        input {
            width: 100%;
            border-bottom: 1px solid #B0B0B0;
            outline: none;
            padding: 2px 4px 11px 4px;
            margin: 13px 3px;
            &:focus {
                border-bottom: 1px solid black;
            }
    }
    input[type=submit] {
                padding: 8px 17px;
                border: none;
                border-radius: 3px;
            }
            .contact-title h3, h6 {
                color: #646464;
                font-weight: 400;
                font-size: 25px;
            }
        @media only screen and (max-width: 600px) { 
            h1 {
                font-size: 30px;
                line-height: 35px;
    }
            }
`;