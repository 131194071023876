import React from 'react';
import { Col, Nav, NavLink, Row } from 'react-bootstrap';
import { Link } from 'react-scroll';
import styled from 'styled-components';

const Footer = () => {
    return (
        <FooterWrapper>
            <Row className='justify-content-between'>
                <Col md={6}>
                    <div style={{ marginBottom: '40px' }} >
                        <img style={{ width: '120px' }} src='/icons/new-logo.svg' alt='img' />
                        <p className='mt-4'>129, Chelsea Road <br />Stamford Bridge, London S2E854</p>
                    </div>
                    <div className='pl-2'>
                        <p className='mb-1'>enqueries@starlingexperts.co.uk</p>
                        <p className='mb-1'>accounts@starlingexperts.co.uk</p>
                        <p className='mb-1'>instructions@starlingexperts.co.uk</p>
                        <p>+447934943680</p>
                    </div>
                </Col>
                <Col md={3} className='text-center'>
                    <h1 className='mt-4 mt-md-0 text-left text-md-center' style={{ fontSize: '17px' }}>IMPORTANT LINKS</h1>
                    <Nav className='d-flex flex-column text-left text-md-center navs'>
                        <Link href='#' className=' mt-4 mb-3' to='at-present'>About Us</Link>
                        <Link href='#' className='mb-3' to='experts'>Join Our Panel</Link>
                        <Link href='#' className='mb-3' to='contact'>Instructions</Link>
                        <Link href='#' to='clients'>Services</Link>
                        {/* <NavLink>Panel Quality</NavLink> */}
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Nav className='d-flex flex-wrap justify-content-center mt-4 footer-nav'>
                    <NavLink>Privacy Policy</NavLink>
                    <NavLink>Cookie Policy</NavLink>
                    <NavLink>Terms & Conditions</NavLink>
                    <NavLink>Contact Us</NavLink>
                </Nav>
            </Row>

        </FooterWrapper>
    );
};

export default Footer;

const FooterWrapper = styled.div`
    padding: 60px 100px;
    background-color: #F3F3F3;
    p{
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        color: #646464;
    }
    .navs a {
        color: #B0B0B0;
        line-height: 22px;
        font-size: 15px;
    }
    .footer-nav a {
        font-size: 16px;
        color: #646464;
        line-height: 25px;
    }
    @media only screen and (max-width: 600px) {
        padding: 30px;
    }
`;